import React, { useState } from "react";
import Select from "react-select/async";
import styled from "styled-components";
import { device } from "../styles/deviceSizes";
import { RESORT, VILLA } from "../constants";

export const SearchBar = styled.form`
  &.homepage-search {
    position: absolute;
    top: -3.5rem;
    z-index: 2;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40vw;
    @media ${device.laptop} {
      width: 60vw;
    }
    @media ${device.tablet} {
      padding: 0.2rem 2rem;
      width: 80vw;
      border-bottom: 1px solid var(--grey);
      filter: unset;
    }
    @media ${device.mobileXL} {
      width: 100vw;
    }
  }

  &.enquire-search {
    .magnifying-glass {
      display: none;
    }

    .input {
      padding: 0;
      border: none;

      margin: 0;
    }
  }

  .input {
    /* z-index: 10000 !important; */
    width: 100% !important;
    display: flex !important;
    outline: none !important;
    margin-right: 1rem;

    &__label {
      display: flex;
      justify-content: space-between;
    }
    &__type {
      font-size: 1.8rem;
      color: #000;
      margin-left: 3rem;
    }

    #react-select-1-listbox,
    #react-select-2-listbox,
    #react-select-3-listbox,
    #react-select-4-listbox,
    #react-select-5-listbox,
    #react-select-5-listbox {
      & > div {
        width: 100%;
      }
      /* z-index: 1000; */
      /* position: absolute; */
      /* height: 70vh; */
      /* max-height: unset !important; */
    }

    & > div {
      /* padding: 1rem; */

      display: flex !important;
      flex-direction: row !important;
      border: none !important;
      width: 100%;

      svg {
        outline: none !important;
      }
    }

    width: 20%;

    &[name="location"] {
      width: 60%;
    }
    &:focus {
      outline: none;
    }
    &:not(:last-of-type) {
      border-right: 1px solid #000;
    }
  }

  svg {
    width: 3rem;
    height: 3rem;
    path {
      fill: var(--darkRed);
    }
  }

  button {
    border-radius: 2px;
  }
`;

const SearchOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  color: #000000;
  :hover {
    font-weight: 600;
  }
  border-bottom: 1px solid rgba(179, 154, 106, 0.2);
  .gatsby-image-wrapper {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
  }
`;

const customSelectStyles = {
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      backgroundColor: "white",
      borderColor: isFocused ? "var(--primary)" : "var(--lightGrey)",
      boxShadow: "var(--lightGrey)",
      "&:hover": {
        borderColor: isFocused ? "var(--primary)" : "var(--lightGrey)",
      },
      borderRadius: "none",
      padding: "1rem 1rem",
      background: "#fff",
      zIndex: 2,
      filter: "drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25))",
    };
  },
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: undefined,
      zIndex: 2,
      padding: "0 10px",
      ":active": {
        ...styles[":active"],
        backgroundColor: undefined,
      },
    };
  },
  menu: (styles) => ({
    ...styles,
    backgroundColor: "var(--greyF8)",
    marginTop: 0,
  }),

  menuList: (styles) => ({
    ...styles,
    width: "100%",
    zIndex: -2,
    "&::-webkit-scrollbar": {
      width: "1rem",
    },

    "&::-webkit-scrollbar-track": {
      backgroundColor: "#EFEFEF",
    },

    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "var(--grey73)",
      height: "2px",
      border: "1px solid transparent",
      backgroundClip: "content-box",
    },
  }),
};

const sortOptions = (a, b) => {
  const regex = /[0-9*#+-]/g;
  const aName = a?.name?.replace(regex, "").trim();
  const bName = b?.name?.replace(regex, "").trim();
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

const Search = ({ className, resorts, villas, placeholder, onChange }) => {
  const [search, setSearch] = useState();

  const options = [
    ...resorts.map(({ name, shortName }) => {
      return {
        value: shortName,
        name,
        label: (
          <SearchOptionWrapper className="input__label">
            <div> {name} </div>
          </SearchOptionWrapper>
        ),
        type: RESORT,
      };
    }),
  ].sort(sortOptions);

  const loadOptions = (search_, callback) => {
    const search = search_.toLowerCase();
    const newSearchOptions = [
      options,
      ...villas.map(({ name, uniqueCode, resort }) => {
        return {
          value: name,
          name,
          label: (
            <SearchOptionWrapper className="input__label">
              <div className="text"> {name} </div>
            </SearchOptionWrapper>
          ),
          uniqueCode,
          resortName: resort?.shortName,
          type: VILLA,
        };
      }),
    ]
      .sort(sortOptions)
      .filter((option) => {
        return (
          option?.value?.toLowerCase().includes(search) ||
          option.name?.toLowerCase().includes(search) ||
          option?.uniqueCode?.toLowerCase().includes(search)
        );
      });
    callback(newSearchOptions);
  };

  return (
    <SearchBar className={className}>
      <Select
        name="name"
        className="input"
        defaultOptions={options}
        loadOptions={loadOptions}
        // menuIsOpen
        placeholder={placeholder}
        isClearable
        value={search}
        onInputChange={(input) => {
          setSearch(input);
        }}
        onChange={(input) => {
          onChange(input);
        }}
        styles={customSelectStyles}
      />
    </SearchBar>
  );
};

export default Search;
